<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="id != null">Edit {{ title }}</b>
      <b v-else>Create {{ title }}</b>
    </span>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label>Name</label>
        <vs-input
          class="w-full"
          v-validate="'required'"
          name="Name"
          v-model="data.name"
        />
        <span class="text-danger text-sm" v-show="errors.has('Name')">{{
          errors.first("Name")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-1/3">
        <Label>Level</Label>
        <multiselect
          class="selectExample"
          v-model="selectedLevel"
          :options="optionalLevel"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          :limit="3"
          placeholder="Select one"
          :searchable="true"
          :custom-label="customLableLevel"
        />
        <span class="text-danger text-sm" v-show="errors.has('Level')">{{
          errors.first("Level")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <Label>Notes</Label>
        <vs-textarea
          class="w-full"
          v-validate="'required'"
          name="Notes"
          v-model="data.notes"
        />
        <span class="text-danger text-sm" v-show="errors.has('Description')">{{
          errors.first("Description")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label>Select Parent</label>
        <multiselect
          class="selectExample"
          v-model="selectName"
          :options="optionName"
          label="Author"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          :limit="3"
          placeholder="Type to search"
          :searchable="true"
          :custom-label="customLableName"
        />
        <span class="text-danger text-sm" v-show="errors.has('Author')">{{
          errors.first("Author")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <Label>External Code</Label>
        <vs-input
          class="w-full"
          v-validate="'required'"
          name="External Code"
          v-model="data.externalCode"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  components: {},
  props: {
    id: {
      type: Number,
    },
    url: {
      type: String,
    },
    title: {
      type: String,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          id: null,
          name: "",
          notes: "",
          externalCode: "",
        },
        selectName: {},
        selectNameID: 0,
        optionName: [],
        selectedLevel: { level: 0 },
        optionalLevel: [
          { level: 0 },
          { level: 1 },
          { level: 2 },
          { level: 3 },
          { level: 4 },
        ],
      };
    },
    handleSubmit() {
      console.log(">>>>", this.selectedLevel.level);
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          if (this.id) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    paramData() {
      const formData = new FormData();
      formData.append("name", this.data.name);
      formData.append("level", this.selectedLevel.level);
      formData.append("parent_id", this.selectName.id);
      formData.append("notes", this.data.notes);
      formData.append("external_code", this.data.externalCode);
      return formData;
    },
    postData() {
      this.$http
        .post(this.url, this.paramData(), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New " + this.title + " Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.url + "/" + this.id, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: this.title + " Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.url + "/" + this.id)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.data.id = resp.data.id;
            this.data.name = resp.data.name;
            this.data.notes = resp.data.notes;
            this.data.externalCode = resp.data.notes;
            this.selectNameID = resp.data.parent_id;
            this.selectedLevel.level = resp.data.level;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getWorkPositionName() {
      this.$http.get(this.url + "/work-positions").then((resp) => {
        if (resp.code == 200) {
          if (resp.data) {
            this.optionName = resp.data;
            if (this.optionName.length > 0) {
              this.optionName.forEach((value) => {
                if (value.id == this.selectNameID) {
                  this.selectName = value;
                }
              });
            } else {
              this.optionName = [];
              this.selectName = { Name: "" };
            }
          } else {
            this.optionName = [];
            this.selectName = { Name: "" };
          }
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    customLableName({ Name }) {
      return ` ${Name}`;
    },
    customLableLevel({ level }) {
      return `${level}`;
    },
  },
  mounted() {
    if (this.id) {
      this.getData();
    }
    this.getWorkPositionName();
  },
  computed: {},
  watch: {
    id(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
  },
};
</script>
<style>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>
